import { GetServerSidePropsContext } from 'next'

import { sanitizeParameter } from '@thg-commerce/enterprise-utils'
import { DayOfWeek } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Status } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Content/StoreByStoreUrlTag'
import {
  getSafeUrlParametersFromReq,
  useSiteDefinition,
} from '@thg-commerce/enterprise-core'

export const getInfoFromQueryParam = (
  queryParam: string | string[],
): { slug: string; page?: number } => {
  if (typeof queryParam === 'string') {
    return { slug: sanitizeParameter(queryParam) }
  }
  if (
    queryParam?.includes('page') &&
    queryParam?.indexOf('page') === queryParam.length - 2
  ) {
    return {
      slug: sanitizeParameter(queryParam[queryParam.length - 3] || ''),
      page: parseInt(
        sanitizeParameter(queryParam[queryParam.length - 1]),
        undefined,
      ),
    }
  }

  return { slug: sanitizeParameter(queryParam[queryParam.length - 1]) }
}

export const getPaginationVaribles = (
  page: number,
  amount: number,
): { offset: number; limit: number } => {
  const offset = amount * page - amount

  return {
    offset,
    limit: amount,
  }
}
export const buildImageSrc = (source: string, maxWidth: number) => {
  const cdnRegExp = /^.+blogscdn.thehut.net\/(?=app|wp-content)/g
  const { imageServiceURL } = useSiteDefinition()

  if (source.includes('blogscdn.thehut.net')) {
    const newSource = source.replace(cdnRegExp, `${imageServiceURL}/v2/`)
    return `${newSource}?width=${maxWidth}`
  }
  return source
}
export const getPathAndParamsFromReq = (
  ctx: GetServerSidePropsContext,
): {
  path: string
  pageParams: {
    pageNumber?: string
    facetFilters?: string
    sortOrder?: string
  }
} => {
  const {
    path,
    pageNumber,
    facetFilters,
    sortOrder,
  } = getSafeUrlParametersFromReq(ctx, [
    'path',
    'pageNumber',
    'facetFilters',
    'sortOrder',
  ]) as {
    path: string[]
    pageNumber?: string
    facetFilters?: string
    sortOrder?: string
  }

  if (!path || !path?.length) {
    return {
      path: '',
      pageParams: {},
    }
  }
  const cleanedPath = path.map((item) =>
    encodeURIComponent(decodeURIComponent(item).replace(/(\b\.list|\/)/g, '')),
  )

  return {
    path: `/${cleanedPath.join('/')}`,
    pageParams: {
      ...(sortOrder && { sortOrder }),
      ...(pageNumber && { pageNumber }),
      ...(facetFilters && { facetFilters: decodeURIComponent(facetFilters) }),
    },
  }
}

export const pastContentDate: string = new Date(
  Date.now() - 34560000000,
).toDateString()

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const isStoreLocatorOpen = (openingTimes) => {
  const currentDate = new Date(new Date().toUTCString())

  const currentDay = openingTimes.find(
    (openingTime) =>
      openingTime.day === DayOfWeek[days[currentDate.getDay() - 1]],
  )

  if (!currentDay) {
    return Status.CLOSED
  }

  const startDate = new Date(currentDate.getTime())
  startDate.setHours(currentDay.openingTime.split(':')[0])
  startDate.setMinutes(currentDay.openingTime.split(':')[1])
  startDate.setSeconds(0)

  const endDate = new Date(currentDate.getTime())
  endDate.setHours(currentDay.closingTime.split(':')[0])
  endDate.setMinutes(currentDay.closingTime.split(':')[1])
  endDate.setSeconds(0)

  return startDate < currentDate && endDate > currentDate
    ? Status.OPEN
    : Status.CLOSED
}

export const replaceString = (string) => {
  return string.replaceAll('-', ' ')
}
